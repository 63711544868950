import styled from '@emotion/styled';

const UpdateButtonWrap = styled('div')({
  button: {
    float: 'left',
    fontSize: '1.4rem',
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
    width: '15rem',
  },
  height: '5rem',
  margin: '3.5rem auto',
  width: '37rem',
});

export default UpdateButtonWrap;
