import styled from '@emotion/styled';

const FormInnerWrap = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  padding: '1rem 0',
  rowGap: '2rem',
});

export default FormInnerWrap;
